/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Navigation
        var navHeader   = $('.header');
        var navMenu     = $('.header__nav');
        var navToggle   = $('.nav__toggle');
        //navHeader.toggleClass('is-off');
        navToggle.click( function(){
            navHeader.toggleClass('is-off');
        } );


        // WOW + Animate.css
        // Add class to elment
        $('.itmit__item.align--dx .image').addClass('wow fadeInLeft');
        $('.itmit__item.align--dx .content').addClass('wow fadeInRight');
        $('.itmit__item.align--sx .image').addClass('wow fadeInRight');
        $('.itmit__item.align--sx .content').addClass('wow fadeInLeft');

        $('.box--fullit .content').addClass('wow fadeInDown');
        $('.box--fullit .image').addClass('wow fadeInUp');

        $('.box--full50it .content').addClass('wow zoomIn');
        $('.box--full50it .image').addClass('wow zoomIn');

        $('.nitmit__item').addClass('wow zoomIn');

        $('.gllry__item').addClass('wow zoomIn');

        // WOW + Animate.css
        // setting and init
        var wow = new WOW({
            offset: 200
        });
        //wow.init();
        var scrolled = false;
        $(window).on('scroll', function() {
            if (!scrolled) {
                scrolled = true;
                wow.init();
            }
        });


        // Magnific Popup
        var MagPopGallery = $('.magnificpopup--gallery');
        MagPopGallery.magnificPopup({
            type: 'image',
            gallery: { enabled:true }
        });

        var MagPopModal = $('.magnificpopup--modal');
        var MagPopModalClose = $('.magnificpopup--modal__close');
        MagPopModal.magnificPopup({
            type: 'inline',
            modal: true,
            mainClass: 'modal__wrap'
        });
        MagPopModalClose.click(
            function(e){
                e.preventDefault();
                $.magnificPopup.close();
            }
        );


        // Slider
        var GallerySldr = $('.box--full50it .slider');
        GallerySldr.slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: false,
            dots: true
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Navigation after scroll
        var navHeader = $('.header');
        var navAnchor = $('#main').first().offset().top;

        $(window).on('scroll', function() {
            var wst = $(window).scrollTop();
            if( wst >= navAnchor ){
                navHeader.addClass('after-slider');
            } else {
                navHeader.removeClass('after-slider');
            }

        });

        // Slick hm__hdr
        var homeHdr = $('.hm__hdr');
        var homeHdrSldr = $('.hm__hdr .slider');
        var homeHdrSldrNv = $('.hm__hdr .slider__nav');
        var homeHdrSldrPrv = $('.hm__hdr .slider__prev');
        var homeHdrSldrNxt = $('.hm__hdr .slider__next');
        var homeHdrSldrScrll = $('.hm__hdr .slider__scroll');

        homeHdrSldr.on('init', function(e, slick) {
            var $firstAnimatingElements = $('.hdr__item.slick-active').find('[data-animation]');
            doAnimations($firstAnimatingElements);
        });

        homeHdrSldr.slick({
            arrows: true,
            autoplay: true,
            autoplaySpeed: 3000,
            infinite: false,
            prevArrow: $('.slider__prev'),
            nextArrow: $('.slider__next'),
            rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
        });

        homeHdrSldr.on('beforeChange', function(e, slick, currentSlide, nextSlide) {
            var $animatingElements = $('.hdr__item[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
            doAnimations($animatingElements);
        });
        function doAnimations(elements) {
            var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
            elements.each(function() {
                var $this = $(this);
                var $animationDelay = $this.data('delay');
                var $animationType = 'animated ' + $this.data('animation');
                $this.css({
                    'animation-delay': $animationDelay,
                    '-webkit-animation-delay': $animationDelay
                });
                $this.addClass($animationType).one(animationEndEvents, function() {
                    $this.removeClass($animationType);
                });
            });
        }

        // Slider Scroll
        homeHdrSldrScrll.click( function(){
            $('html, body').animate({ scrollTop: ( $('#main').first().offset().top - 100 ) }, 500);
        } );

        // Slider Blur
        $(window).on('scroll', function () {
            var blur = $(document).scrollTop() / 50;
            if( 10 < blur ){ blur = 10; }

            if( blur == 0 ){
                homeHdrSldr.slick('slickPlay');
            } else {
                homeHdrSldr.slick('slickPause');
            }
            homeHdr.css({
                '-webkit-filter': 'blur('+blur+'px)',
                '-moz-filter': 'blur('+blur+'px)',
                '-ms-filter': 'blur('+blur+'px)',
                '-o-filter': 'blur('+blur+'px)',
                'filter': 'blur('+blur+'px)'
            });
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
